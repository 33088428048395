/* stylelint-disable selector-class-pattern */
:root {
    [data-theme='jeu-machine'] {
        /* ArticleDocument */
        --article-background: ##1B331B;

        /* Body */
        --article-text-color: ##FFFFFF;
    }
}

:global {
    :local(.container) {
        --color-orange: #FF4325;
        --color-yellow: #FD0;
        --color-white: #FFF;
        --color-red: #751304;
        --color-green: #1B331B;

        h1 {
            margin-top: 40px;
            color: var(--color-orange);
        }

        h3 {
            width: 100%;
            color: var(--color-yellow);
            text-align: center;
        }

        .block-heading {
            h3 {
                margin-bottom: 40px;
                font-family: var(--font-agrandir);
                font-size: 42px;
                font-weight: 800;
                text-transform: none;

                @media (--medium-viewport) {
                    font-size: 48px;
                }
            }

            h4 {
                font-family: var(--font-agrandir);
                font-size: 20px;
                text-align: center;
                text-transform: none;

                @media (--medium-viewport) {
                    font-size: 24px;
                }
            }

            h5 {
                font-family: var(--font-text);
                font-size: 20px;
                font-style: italic;
                font-weight: 400;
                text-align: left;
                text-transform: none;

                @media (--medium-viewport) {
                    font-size: 24px;
                }
            }
        }

        .block-text {
            ul {
                padding: 0;
                margin: 40px 0 0;
                list-style: none;
            }

            li {
                margin-bottom: 0;
                font-family: var(--font-agrandir);
                font-size: 20px;
                font-weight: 500;

                @media (--medium-viewport) {
                    font-size: 24px;
                }
            }
        }

        .block-quote {
            margin: 80px auto;
            color: var(--color-yellow);

            blockquote p {
                font-family: var(--font-agrandir);
                font-size: 28px;
                font-weight: 800;
                text-transform: none;

                @media (--medium-viewport) {
                    font-size: 36px;
                }
            }
        }

        .block-section {
            padding: 80px 0;

            &[data-theme='jeu-orange'] {
                background-color: var(--color-orange);
                color: var(--color-white);

                h4 {
                    color: var(--color-red);
                }

                li {
                    color: var(--color-red);
                }
            }

            &[data-theme='jeu-red'] {
                background-color: var(--color-red);

                h3, h4 {
                    color: var(--color-orange);
                }

                .block-quote {
                    color: var(--color-orange);
                    font-weight: 500;
                    text-align: center;
                }
            }

            &[data-theme='jeu-green'] {
                background-color: var(--color-green);

                h3, li {
                    color: var(--color-yellow);
                }
            }
        }
    }
}
